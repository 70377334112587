<template>
  <!-- index.vue -->

  <v-app
    :style="{
      background: $vuetify.theme.themes[theme].background,
      color: $vuetify.theme.themes[theme].color,
    }"
    class="app-admin-wrap-layout-6"
  >
    <side-bar></side-bar>
    <base-view />
    <v-fade-transition v-if="getAppLoading">
      <v-overlay  absolute color="primary lighten-5">
          <v-card class="mx-auto dark--text" color="white" striped max-width="1000" :loading="getAppLoading">
            <template slot="progress">
              <v-progress-linear color="secondary" indeterminate></v-progress-linear>
            </template>
            <v-card-text class="dark--text text-center font-weight-medium">
              Payment Progressing....This will take a moment......Don't Refresh or Reload the Page.....
            </v-card-text>
          </v-card>
      </v-overlay>
    </v-fade-transition>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import menuItems from "@/constants/sidebar-menu";

export default {
  name: "FullLayout",
  components: {
    SideBar: () => import("@/layouts/component/Sidebar"),
    BaseView: () => import("@/layouts/component/View"),
  },
  computed: {
    ...mapGetters(["getThemeMode", "getAppLoading"]),

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  created() {
    let reportRouteName = []
    const reportRouters = (menuItems.find(item => item.title === 'Reports')).children;
    reportRouters.map(item => reportRouteName.push(item.route));
    const currRouteName = this.$route.name;
    if (['LP Entry', 'Home', 'Producer', 'On Air', 'Staff', 'Tally', 'Sound Effect'].indexOf(currRouteName) !== -1 || 
      reportRouteName.indexOf(currRouteName) !== -1) {
      this.changeVerticalSidebarDrawer();
    }
  },
  methods: {
    ...mapActions(["changeVerticalSidebarDrawer"])
  }
};
</script>
<style lang="scss">
.app-admin-wrap-layout-6 {
  .sidebar-six {
    &.theme--dark.v-navigation-drawer {
      background-color: #111827;
      // background-color: #111827;
    }

    // .top-toolbar {
    //   background-color: #111827;
    // }
  }
}
div.theme--dark .v-navigation-drawer {
  background-color: #272727 !important;
}
</style>
